<template>
  <div>
    <v-card class="pa-4" max-width="1024" min-height="300">
      <v-toolbar>
        <v-tabs v-model="tabs" color="primary" dark slider-color="primary">
          <v-tab> Account Info </v-tab>
          <v-tab> Card Info </v-tab>
          <v-tab> Payments </v-tab>
          <v-tab> Failed Charges </v-tab>
          <v-tab> Actions </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-card-text v-if="accountModel">
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-row>
              <v-col cols="12" sm="12" md="6" class="pl-4">
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Account:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.account }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Customer:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.customer }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Payment Gateway:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.gateway }}
                  </div>
                </v-row>
                <v-row
                  v-if="accountModel.remita && accountModel.remita.mandateId"
                  dense
                  class="align-baseline"
                >
                  <div class="overline mr-3">Remita Mandate ID:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.remita.mandateId }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Email:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.email }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Name:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.firstname }} {{ accountModel.lastname }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Status:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.status }}
                  </div>
                </v-row>
                <v-row
                  dense
                  class="align-baseline"
                  v-if="accountModel.status !== 'pending'"
                >
                  <div class="overline mr-3">Complete Reason:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.doneReason }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Created:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatDateMed(accountModel.createdAt) }} -
                    {{ formatTime(accountModel.createdAt) }}
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Plan:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.planName }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Amount:</div>
                  <div class="subtitle-2 font-weight-bold blue--text">
                    {{ formatCurrency(accountModel.amount, null, 2) }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Balance:</div>
                  <div
                    class="
                      subtitle-2
                      font-weight-bold
                      orange--text
                      lighten-text--5
                    "
                  >
                    {{ formatCurrency(accountModel.balance, null, 2) }}
                  </div>
                </v-row>

                <v-row dense class="align-baseline">
                  <div class="overline mr-3">First Payment:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatCurrency(accountModel.firstPayment, null, 2) }}
                  </div>
                </v-row>

                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Last Payment:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatDateMed(accountModel.lastPaymentDate) }} -
                    {{ formatTime(accountModel.lastPaymentDate) }}
                  </div>
                </v-row>
                <v-row
                  dense
                  class="align-baseline"
                  v-if="accountModel.status == 'pending'"
                >
                  <div class="overline mr-3">Next Payment:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatDateMed(accountModel.nextPaymentDate) }} -
                    {{ formatTime(accountModel.nextPaymentDate) }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Last Charge Attempt:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatDateMed(accountModel.lastChargeAttemptDate) }} -
                    {{ formatTime(accountModel.lastChargeAttemptDate) }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Recurring Amount:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ formatCurrency(accountModel.recurringAmount, null, 2) }}
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">Recurring Cycle:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.recurringCycle }} Days
                  </div>
                </v-row>
                <v-row dense class="align-baseline">
                  <div class="overline mr-3">No of Payments:</div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ accountModel.numOfPayments }}
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="12" sm="12" class="pl-0">
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-left">Brand</th>
                          <th class="text-left">Card Type</th>
                          <th class="text-left">Last 4 digits</th>
                          <th class="text-left">Exp Month</th>
                          <th class="text-left">Exp Year</th>
                          <th class="text-left">Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in accountModel.paymentDetails"
                          :key="item._id"
                        >
                          <td>{{ item.account_name }}</td>
                          <td>{{ item.brand }}</td>
                          <td>{{ item.card_type }}</td>
                          <td>{{ item.last4 }}</td>
                          <td class="font-weight-bold light-green--text">
                            {{ item.exp_month }}
                          </td>
                          <td class="font-weight-bold light-green--text">
                            {{ item.exp_year }}
                          </td>
                          <td>
                            {{ formatDateMed(item.date) }} -
                            {{ formatTime(item.date) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="12" sm="12" class="pl-0">
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Amount</th>
                          <th class="text-left">Balance Before</th>
                          <th class="text-left">Balance After</th>
                          <th class="text-left" v-if="accountModel.remita.rrr">
                            RRR
                          </th>
                          <th class="text-left">Tansaction ID</th>
                          <!-- <th class="text-left">Tansaction Ref</th> -->
                          <th class="text-left">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in accountModel.payHistory"
                          :key="item._id"
                        >
                          <td class="font-weight-bold">
                            {{ formatCurrency(item.amount, null, 2) }}
                          </td>
                          <td class="font-weight-bold cyan--text">
                            {{ formatCurrency(item.balanceBefore, null, 2) }}
                          </td>
                          <td class="font-weight-bold amber--text">
                            {{ formatCurrency(item.balanceAfter, null, 2) }}
                          </td>
                          <td class="font-weight-light">{{ item.rrr }}</td>
                          <td class="font-weight-light">{{ item.tranxId }}</td>
                          <!-- <td class="font-weight-light">
                            {{ item.tansactionRef }}
                          </td> -->
                          <td>
                            {{ formatDateMed(item.date) }} -
                            {{ formatTime(item.date) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="12" sm="12" class="pl-0">
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Amount</th>
                          <th class="text-left">Balance</th>
                          <th class="text-left">Transaction ID</th>
                          <th class="text-left">Reason</th>
                          <th class="text-left">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in accountModel.errorHistory"
                          :key="item._id"
                        >
                          <td class="font-weight-bold">
                            {{ formatCurrency(item.amount, null, 2) }}
                          </td>
                          <td class="font-weight-bold cyan--text">
                            {{ formatCurrency(item.balance, null, 2) }}
                          </td>
                          <td class="font-weight-bold amber--text">
                            {{ item.tranxId }}
                          </td>
                          <td class="font-weight-light">{{ item.reason }}</td>
                          <td>
                            {{ formatDateMed(item.date) }} -
                            {{ formatTime(item.date) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <v-btn
                  v-if="accountModel.status === 'pending'"
                  rounded
                  color="red"
                  @click="chargeManually"
                  >Manual Charge</v-btn
                >
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog
      ref="dialog"
      v-model="dateOpen"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateOpen = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="saveDate"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  computed: {
    ...mapGetters([
      "formatCurrency",
      "formatCurrencyDecimal",
      "formatDateMed",
      "formatTime",
    ]),
    recurCharge() {
      return (
        this.accountModel.planRecurringAmount * this.accountModel.selectedRecur
      );
    },
  },
  created() {},
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpPatch", "httpDelete"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },
    decrementNumRecur() {
      if (this.accountModel.selectedRecur > 1) {
        this.accountModel.selectedRecur -= 1;
      }
      if (this.accountModel.selectedRecur < 1) {
        this.accountModel.selectedRecur = 1;
      }
    },
    incrementNumRecur() {
      if (this.accountModel.balance > this.recurCharge) {
        this.accountModel.selectedRecur += 1;
      }
    },
    saveDate() {
      this.$refs.dialog.save(this.date);
      const newDate = new Date(this.date);
      const oldDate = new Date(this.accountModel.nextPaymentDate);
      this.accountModel.nextPaymentDate = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        oldDate.getHours(),
        oldDate.getMinutes(),
        oldDate.getSeconds(),
        oldDate.getMilliseconds()
      );
    },
    openDateEdit() {
      this.date = new Date(
        new Date(this.accountModel.nextPaymentDate) -
          new Date(this.accountModel.nextPaymentDate).getTimezoneOffset() *
            60000
      )
        .toISOString()
        .substr(0, 10);
      this.dateOpen = true;
    },
    validate: function () {
      this.$refs.profileForm.validate();
    },
    statusChange(val) {
      // console.log(val);
      this.statusChanged = val === "done" ? true : false;
    },
    updateData: async function () {
      console.log("updating===", this.accountModel);
      if (this.statusChanged) {
        this.accountModel.doneReason = "";
      }
      this.accountModel.recurringCycle =
        this.accountModel.planRecurringCycle * this.accountModel.selectedRecur;
      this.accountModel.recurringAmount =
        this.accountModel.planRecurringAmount * this.accountModel.selectedRecur;

      let params = await this.httpPatch({
        withAuth: true,
        url: "/account",
        data: this.accountModel,
      });
      return params.data;
    },
    async chargeManually() {
      try {
        await this.httpPost({
          withAuth: true,
          url: "/api/manual-charge",
          data: { id: this.accountModel._id },
        });
        this.showMessage({
          text: `Manual charge submitted for ${this.accountModel.account}`,
          error: false,
        });
        // console.log(params.data);
      } catch (error) {
        console.error(error);
        this.showMessage({
          text: "Manual Charge Failed.",
          error: true,
        });
      }
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with form data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            await this.updateData();
            this.loading = false;
            this.showMessage({ text: "Product saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  data() {
    return {
      search: "",
      statusChanged: false,
      tabs: 0,
      accountModel: {},
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      schedule: ["Monthly", "Weekly"],
      valid: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateOpen: false,
      menu2: false,
      statusTypes: ["pending", "done"],
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

