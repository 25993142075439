<template>
  <div>
    <v-card class="pa-4" max-width="720" min-height="300">
      <v-card-title
        class="mb-3 panel-title primary--text"
        v-if="accountModel._id"
      >
        Edit Account
        <!-- <span class="ml-2 font-weight-light">{{ accountModel.email }}</span> -->
      </v-card-title>
      <v-card-title class="mb-3 panel-title primary--text" v-else>
        Add Account
      </v-card-title>
      <v-card-text v-if="accountModel">
        <v-form
          ref="profileForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                autofocus
                dense
                label="Name"
                v-model="accountModel.account"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="name"
              ></v-text-field>

              <v-text-field
                label="Customer"
                dense
                v-model="accountModel.customer"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="customer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <div class="mt-n1 mb-5">
                <div class="caption">Next Payment Date</div>
                <div>
                  {{ formatDateMed(accountModel.nextPaymentDate) }} :
                  {{ formatTime(accountModel.nextPaymentDate) }}
                  <v-btn @click="openDateEdit" x-small fab
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </div>
              </div>

              <v-select
                @change="statusChange"
                class=""
                outlined
                dense
                :items="statusTypes"
                v-model="accountModel.status"
                label="Status"
              ></v-select>

              <v-select
                class=""
                outlined
                dense
                item-value="_id"
                item-text="name"
                return-object
                :items="plans"
                v-model="selectedPlan"
                label="Plan"
              ></v-select>

              <v-textarea
                v-if="statusChanged"
                label="Reason"
                v-model="accountModel.doneReason"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                placeholder="Reason for changing status to DONE"
                outlined
                type="reason"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <!-- <v-divider class="mb-2 mt-n4"></v-divider> -->
          <v-row>
            <v-col>
              <div v-if="accountModel" class="mb-6">
                <div
                  class="text-overline mb-1"
                  v-if="this.accountModel.schedule === 'Monthly'"
                >
                  {{ accountModel.selectedRecur }} Month<span
                    v-if="accountModel.selectedRecur > 1"
                    >s</span
                  >
                  Recurring
                </div>
                <div v-else class="text-overline mb-1">
                  {{ accountModel.selectedRecur }} Week<span
                    v-if="accountModel.selectedRecur > 1"
                    >s</span
                  >
                  Recurring
                </div>
                <v-row dense class="justify-start pl-1 align-center">
                  <div class="font-weight-bold title">
                    {{ formatCurrencyDecimal(recurCharge, false, 2) }}
                  </div>

                  <v-btn @click="incrementNumRecur" icon color="primary"
                    ><v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                  <v-btn @click="decrementNumRecur" icon color="accent"
                    ><v-icon>mdi-minus-circle</v-icon></v-btn
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mr-6 mt-4">
              <v-btn @click="$emit('close-dialog')" outlined block>Close</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mt-4">
              <v-btn :loading="loading" color="primary" @click="submit" block
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
    <v-dialog
      ref="dialog"
      v-model="dateOpen"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateOpen = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="saveDate"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
import toast from "../../utils/toast";
// import planChange from "./changePlan.vue";
export default {
  computed: {
    ...mapGetters([
      "formatCurrency",
      "formatCurrencyDecimal",
      "formatDateMed",
      "formatTime",
    ]),
    recurCharge() {
      return (
        this.accountModel.planRecurringAmount * this.accountModel.selectedRecur
      );
    },
  },
  created() {
    this.getPlans();
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpPatch", "httpDelete"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },
    async getPlans() {
      try {
        const resp = await this.httpGet({
          withAuth: true,
          url: "/plan/all",
          data: {},
        });
        this.plans = resp.data;
        // console.log("Plans-----", this.plans);
      } catch (error) {
        toast.showError(error);
      }
    },
    decrementNumRecur() {
      if (this.accountModel.selectedRecur > 1) {
        this.accountModel.selectedRecur -= 1;
      }
      if (this.accountModel.selectedRecur < 1) {
        this.accountModel.selectedRecur = 1;
      }
    },
    incrementNumRecur() {
      if (this.accountModel.balance > this.recurCharge) {
        this.accountModel.selectedRecur += 1;
      }
    },
    saveDate() {
      this.$refs.dialog.save(this.date);
      const newDate = new Date(this.date);
      const oldDate = new Date(this.accountModel.nextPaymentDate);
      this.accountModel.nextPaymentDate = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        oldDate.getHours(),
        oldDate.getMinutes(),
        oldDate.getSeconds(),
        oldDate.getMilliseconds()
      );
    },
    openDateEdit() {
      this.date = new Date(
        new Date(this.accountModel.nextPaymentDate) -
          new Date(this.accountModel.nextPaymentDate).getTimezoneOffset() *
            60000
      )
        .toISOString()
        .substr(0, 10);
      this.dateOpen = true;
    },
    validate: function () {
      this.$refs.profileForm.validate();
    },
    statusChange(val) {
      // console.log(val);
      this.statusChanged = val === "done" ? true : false;
    },
    updateData: async function () {
      // console.log("updating===", this.accountModel);
      if (this.statusChanged) {
        this.accountModel.doneReason = "";
      }
      this.accountModel.recurringCycle =
        this.accountModel.planRecurringCycle * this.accountModel.selectedRecur;
      this.accountModel.recurringAmount =
        this.accountModel.planRecurringAmount * this.accountModel.selectedRecur;

      let params = await this.httpPatch({
        withAuth: true,
        url: "/account",
        data: { ...this.accountModel, selectedPlan: this.selectedPlan },
      });
      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with form data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            await this.updateData();
            this.loading = false;
            this.showMessage({ text: "Product saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  watch: {
    accountModel(val) {
      this.selectedPlan = val.planId;
    },
  },
  data() {
    return {
      search: "",
      selectedPlan: null,
      statusChanged: false,
      plans: [],
      accountModel: {},
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      schedule: ["Monthly", "Weekly"],
      valid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateOpen: false,
      menu2: false,
      statusTypes: ["pending", "done"],
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

